import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`ocorrencias?${parametros}`)
  },

  async buscar (id) {
    return await axios.get(`ocorrencias/${id}`)
  },

  async inserir (dados) {
    return await axios.post('ocorrencias', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`ocorrencias/${id}`, dados)
  },

  async buscarVisibilidades () {
    return await axios.get('visibilidades-ocorrencia')
  },

  // Veículos
  async buscarVeiculos (id) {
    return await axios.get(`ocorrencias/${id}/veiculos`)
  },

  async editarVeiculo (id, dados) {
    return await axios.put(`veiculos-ocorrencia/${id}`, dados)
  },

  async adicionarVeiculo (dados) {
    return await axios.post('veiculos-ocorrencia', dados)
  },

  async removerVeiculo (id) {
    return await axios.delete(`veiculos-ocorrencia/${id}`)
  },

  // Pessoas
  async buscarPessoas (id) {
    return await axios.get(`ocorrencias/${id}/pessoas`)
  },

  async adicionarPessoa (dados) {
    return await axios.post('pessoa-ocorrencia', dados)
  },

  async removerPessoa (ocorrenciaId, pessoaId) {
    return await axios.delete(`ocorrencias/${ocorrenciaId}/pessoa/${pessoaId}`)
  },

  // Ocorrencias relacionadas
  async buscarOcorrenciasRelacionadas (ocorrenciaId) {
    return await axios.get(`ocorrencias/${ocorrenciaId}/relacionadas`)
  },

  async adicionarOcorrenciaRelacionada (ocorrenciaId, dados) {
    return await axios.post(`ocorrencias/${ocorrenciaId}/relacionadas`, dados)
  },

  async removerOcorrenciaRelacionada (ocorrenciaId, dados) {
    return await axios.delete(`ocorrencias/${ocorrenciaId}/relacionadas`, { data: dados })
  },

  // Anexos
  async buscarAnexos (ocorrenciaId) {
    return await axios.get(`ocorrencias/${ocorrenciaId}/anexos`)
  },

  async adicionarAnexo (dados) {
    return await axios.post('anexos-ocorrencia', dados)
  },

  async removerAnexo (anexoId) {
    return await axios.delete(`anexos-ocorrencia/${anexoId}`)
  },

  // Acompanhamentos
  async acompanhamentos (ocorrenciaId) {
    return await axios.get(`ocorrencias/${ocorrenciaId}/acompanhamentos`)
  },

  // Importar PDF
  async assimilarBoletim (dados) {
    return await axios.post('ocorrencias/assimilar-boletim', dados)
  },

}
